<template>
  <div class="md-layout text-center">
    <div
      class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100"
    >
      <login-card header-color="rose">
        <h4
          slot="title"
          class="title"
        >
          Log in
        </h4>

        <md-field
          slot="inputs"
          class="md-form-group"
        >
          <md-icon>email</md-icon>
          <label>Email...</label>
          <md-input
            v-model="login.email"
            type="email"
            @keyup.enter="onLogin"
          />
        </md-field>
        <md-field
          slot="inputs"
          class="md-form-group"
        >
          <md-icon>lock_outline</md-icon>
          <label>Password...</label>
          <md-input
            v-model="login.password"
            type="password"
            @keyup.enter="onLogin"
          />
        </md-field>
        <md-button
          slot="footer"
          class="md-simple md-success md-lg md-rose"
          @click="onLogin"
        >
          Lets Go
        </md-button>
      </login-card>
    </div>
  </div>
</template>
<script>
import { LoginCard } from '@/components';

export default {
  components: {
    LoginCard,
  },
  data() {
    return {
      firstname: null,
      login: {
        email: null,
        password: null,
      },
    };
  },
  mounted() {
    if (this.validateSession()) {
      this.$router.push({ name: 'Dashboard' });
    }
  },
  methods: {
    onLogin() {
      this.request(this.$API.LOGIN, 'post', this.login, (data) => {
        this.setSession(data);
        this.$store.dispatch('school_settings/getSchoolSettings', {
          cb: () => {
            this.$router.push({ name: 'Dashboard' });
          },
          force: true,
        });
      }, null, true);
    },
  },
};
</script>
